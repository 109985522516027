<template>
  <!-- begin::OfferStandard profile tabs -->
  <div v-if="isAuthenticated && !Loading">
    <div class="manager-title mb-1">
      <div>
        {{ $t("OFFERS.STANDARD.TITLE_EDIT") + " - ID: " + ShipmentID }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <v-tabs v-model="ActiveTab" align-with-title class="mb-3 mx-0">
      <v-tabs-slider color="#61bb0c"></v-tabs-slider>

      <v-tab href="#review">
        <span v-if="AlertCountOther === 0">
          {{ this.$i18n.t("OFFERS.COMMON.REVIEW") }}
        </span>
        <v-badge v-else inline color="bg-danger text-white">
          <template v-slot:badge>
            {{ AlertCountOther }}
          </template>
          <span class="mr-3">{{ this.$i18n.t("OFFERS.COMMON.REVIEW") }}</span>
        </v-badge>
      </v-tab>

      <v-tab href="#messages">
        <span v-if="AlertCountChat === 0">
          {{ this.$i18n.t("OFFERS.COMMON.MESSAGES") }}
        </span>
        <v-badge v-else inline color="bg-danger text-white">
          <template v-slot:badge>
            {{ AlertCountChat }}
          </template>
          <span class="mr-3">{{ this.$i18n.t("OFFERS.COMMON.MESSAGES") }}</span>
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-tabs-items class="mx-0" :value="ActiveTab">
      <v-tab-item value="review">
        <OffersStandardEdit :shipment-id="ShipmentID" :back-route="BackRoute" />
      </v-tab-item>

      <v-tab-item value="messages">
        <OffersStandardChat
          :shipment-id="ShipmentID"
          :back-route="BackRoute"
          @chat-new-messages="AlertCountChat = $event"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
  <!-- end::OfferStandard profile tabs -->
</template>

<script>
import { mapGetters } from "vuex";

import ShipmentService from "@/core/services/api/v2/shipment.service";
import OffersStandardChat from "@/view/pages/manager/shipments/offer/standard/Chat.vue";
import OffersStandardEdit from "@/view/pages/manager/shipments/offer/standard/Edit.vue";

export default {
  name: "FaresStandardTabs",
  components: {
    OffersStandardChat,
    OffersStandardEdit
  },

  computed: {
    ...mapGetters(["isAuthenticated"]),
    ActiveTab: {
      set(ActiveTab) {
        this.$router.replace({ query: { ...this.$route.query, ActiveTab } });
      },
      get() {
        return this.$route.query.ActiveTab;
      }
    },
    BackRoute() {
      let fromPage = this.$route.query.FromPage;
      if (fromPage === "chats") {
        return "/manager/chats?ActiveTab=offers";
      } else {
        return "/manager/offers?ActiveTab=standard";
      }
    }
  },

  data() {
    return {
      Loading: true,
      ShipmentID: 0,
      AlertCountChat: 0,
      AlertCountOther: 0
    };
  },

  mounted() {
    if (!this.$route.params.id) {
      this.$router.push("/manager/offers?ActiveTab=standard");
    } else {
      this.ShipmentID = parseInt(this.$route.params.id);
      this.loadAlertCount();
      this.Loading = false;
    }
  },

  methods: {
    loadAlertCount() {
      this.AlertCountChat = 0;
      this.AlertCountOther = 0;

      ShipmentService.getAlertCountAtOffer(this.ShipmentID).then(response => {
        this.AlertCountChat = response.AlertCountChat;
        this.AlertCountOther = response.AlertCountOther;
      });
    }
  }
};
</script>
