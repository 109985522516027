<template>
  <!-- begin::OfferStandard edit -->
  <div v-if="InitialData">
    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <!-- begin::Status -->
        <div class="mt-6 d-flex align-items-center justify-content-between">
          <div>
            <span class="manager-subtitle mr-9">
              {{ $t("OFFERS.COMMON.STATUS") }}
            </span>
            <span
              class="white-box shadow-box border-2 p-3 text-uppercase"
              :class="formatStatusClass(InitialData.ShipmentStatusID)"
              style="white-space: nowrap"
            >
              {{ formatStatusName(InitialData.ShipmentStatusID) }}
            </span>
          </div>
          <button
            v-if="InitialData.ShipmentStatusID < 6"
            class="btn btn-pill btn-white button-form button-form-primary"
            @click="reloadOfferData"
          >
            <inline-svg src="/media/icons/undo-primary.svg" class="mr-3" />
            {{ $t("GENERIC.DEFAULT_VALUES") }}
          </button>
        </div>

        <div
          v-if="
            InitialData.ShipmentStatusID < 6 ||
              InitialData.ShipmentStatusID == 14
          "
          class="mt-6"
        >
          <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
          <span v-if="isTransporter">
            {{
              $t(
                "OFFERS.STANDARD.STATUS_INFO." +
                  InitialData.ShipmentStatusID +
                  ".TRANSPORTER"
              )
            }}
          </span>
          <span v-else>
            {{
              $t(
                "OFFERS.STANDARD.STATUS_INFO." +
                  InitialData.ShipmentStatusID +
                  ".SENDER"
              )
            }}
          </span>
        </div>
        <!-- end::Status -->

        <!-- begin::Other company & price -->
        <div class="manager-subtitle mt-9">
          {{ $t("OFFERS.STANDARD.ABSTRACT") }}
        </div>

        <div class="row">
          <div class="form-group col-lg-6 mb-0">
            <label class="mb-0">
              {{
                $t(
                  isTransporter
                    ? "OFFERS.COMMON.SENDER"
                    : "OFFERS.COMMON.TRANSPORTER"
                )
              }}
            </label>
            <h3 class="mt-0">
              {{
                isTransporter
                  ? InitialData.Sender.TradeName
                  : InitialData.Transporter.TradeName
              }}
            </h3>
          </div>

          <div class="form-group col-lg-6 mb-0">
            <label>
              {{ $t("OFFERS.COMMON.FARE") }}
              {{ $t("FARE_TYPE_NAMES_BY_ID." + InitialData.FareTypeID) }}
              <br />
              {{ $t("OFFERS.COMMON.PRICE_TOTAL") }}
              ({{
                InitialData.Currency +
                  ", " +
                  $t("OFFERS.COMMON.TAXES_INCLUDED")
              }})
            </label>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-6">
            <label class="mb-0">
              {{ $t("SHIPMENTS.PAYMENT_DATA.TITLE") }}
            </label>
            <div v-if="InitialData.PaymentData.CardType === 'External'">
              <strong class="text-danger">
                {{ $t("SHIPMENTS.PAYMENT_DATA.EXTERNAL") }}
              </strong>
            </div>
            <div v-else>
              <strong>{{ $t("SHIPMENTS.PAYMENT_DATA.CREDIT_CARD") }}:</strong>
              {{ InitialData.PaymentData.CardType }}
              <br />
              {{
                $t("SHIPMENTS.PAYMENT_DATA.CARD_NUMBER") +
                  ": **** **** **** " +
                  InitialData.PaymentData.CardLast4
              }}
              <br />
              {{
                $t("SHIPMENTS.PAYMENT_DATA.AMOUNT") +
                  ": " +
                  InitialData.PaymentData.Amount.toFixed(2).replace(".", ",") +
                  " " +
                  InitialData.PaymentData.Currency
              }}
              <br />
              {{ $t("SHIPMENTS.PAYMENT_DATA.DATE") + ": " + formatCreatedAt() }}
            </div>
          </div>

          <div class="form-group col-lg-3">
            <v-text-field
              class="form-control shortInput"
              v-model="Fields.Price"
              :placeholder="$t('OFFERS.COMMON.PRICE_TOTAL')"
              type="number"
              min="0"
              step="0.01"
              :rules="[rules.requiredMin0]"
              :readonly="!canEditPrice"
            />
          </div>
        </div>
        <!-- end::Other company & price -->

        <!-- begin::Origin & Destination -->
        <div class="row">
          <div
            v-for="whichSide in ['OriginAddress', 'DestinationAddress']"
            :key="whichSide"
            class="col-lg-6"
          >
            <h4 class="font-weight-bolder">
              <inline-svg src="/media/icons/pin-1-primary.svg" class="mr-3" />
              {{
                $t(
                  whichSide === "OriginAddress"
                    ? "OFFERS.STANDARD.ORIGIN_DATA"
                    : "OFFERS.STANDARD.DESTINATION_DATA"
                )
              }}
            </h4>
            <p class="mt-6 font-weight-bolder">
              {{
                $t(
                  whichSide === "OriginAddress"
                    ? "OFFERS.STANDARD.ORIGIN_ADDRESS"
                    : "OFFERS.STANDARD.DESTINATION_ADDRESS"
                )
              }}
            </p>
            <div class="row">
              <div class="col-lg-12 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].TradeName"
                  readonly
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].Street"
                  readonly
                />
              </div>
              <div class="col-lg-4 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].PostalCode"
                  readonly
                />
              </div>
              <div class="col-lg-6 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].City"
                  readonly
                />
              </div>
              <div class="col-lg-6 form-group">
                <v-text-field
                  class="form-control"
                  :value="ProvinceNames[InitialData[whichSide].ProvinceID]"
                  readonly
                />
              </div>
            </div>
            <p class="mt-3 font-weight-bolder">
              {{
                $t(
                  whichSide === "OriginAddress"
                    ? "OFFERS.STANDARD.ORIGIN_CONTACT"
                    : "OFFERS.STANDARD.DESTINATION_CONTACT"
                )
              }}
            </p>
            <div class="row">
              <div class="col-lg-12 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].ContactName"
                  readonly
                />
              </div>
              <div class="col-lg-6 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].ContactEmail"
                  readonly
                >
                  <template v-slot:prepend-inner>
                    <inline-svg src="/media/icons/contact-gray.svg" />
                  </template>
                </v-text-field>
              </div>
              <div class="col-lg-6 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].ContactPhone"
                  readonly
                >
                  <template v-slot:prepend-inner>
                    <inline-svg src="/media/icons/phone-gray.svg" />
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Origin & Destination -->

        <!-- begin::Other data, just for Senders -->
        <div v-if="!isTransporter && InitialData.CanEdit">
          <div class="manager-subtitle mt-9">
            {{ $t("OFFERS.COMMON.OTHER_DATA") }}
          </div>

          <div class="row">
            <!-- begin::Observers -->
            <div
              class="form-group"
              :class="
                InvoiceCenterOptions.length > 0 ? 'col-lg-6' : 'col-lg-12'
              "
            >
              <div class="row">
                <div class="col-lg-11">
                  <label>{{ $t("OFFERS.COMMON.OBSERVERS") }}</label>
                  <v-select
                    id="ObserversSelect"
                    multiple
                    v-model="Fields.Observers"
                    :items="ObserverOptions"
                    chips
                    small-chips
                    deletable-chips
                    class="form-control px-2"
                    style="padding-top: 0.35em !important; height: fit-content !important"
                  ></v-select>
                </div>
                <div class="col-lg-1 pt-11 pl-0">
                  <span class="action-icon" @click="openObserverDialog">
                    <inline-svg src="/media/icons/add-primary.svg" />
                  </span>

                  <!--begin::Observer dialog-->
                  <v-dialog
                    v-model="ObserverDialog.Show"
                    transition="dialog-bottom-transition"
                    max-width="700px"
                  >
                    <v-card>
                      <div class="bg-primary text-white font-size-h5 py-3 px-7">
                        {{ $t("OBSERVERS.TITLE_ADD") }}
                      </div>

                      <v-card-text class="pt-6 pb-3">
                        <v-form
                          ref="formObserverDialog"
                          v-model="ObserverDialog.Valid"
                          lazy-validation
                          class="form"
                        >
                          <div class="row">
                            <div class="form-group col-lg-6">
                              <label>
                                {{ $t("MY_PROFILE.NICK") }}
                              </label>
                              <v-text-field
                                class="form-control"
                                v-model="ObserverDialog.Nick"
                                :placeholder="$t('MY_PROFILE.NICK')"
                                required
                                :rules="[rules.required]"
                              />
                            </div>

                            <div class="form-group col-lg-6">
                              <label>
                                {{ $t("MY_PROFILE.FULLNAME") }}
                              </label>
                              <v-text-field
                                class="form-control"
                                v-model="ObserverDialog.Name"
                                :placeholder="$t('MY_PROFILE.FULLNAME')"
                                required
                                :rules="[rules.required]"
                              />
                            </div>

                            <div class="form-group col-lg-6">
                              <label>
                                {{ $t("OBSERVERS.NICK_COMPANY") }}
                              </label>
                              <v-text-field
                                class="form-control"
                                v-model="ObserverDialog.NickCompany"
                                :placeholder="$t('OBSERVERS.NICK_COMPANY')"
                                required
                                :rules="[rules.required]"
                              />
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-lg-6">
                              <label>
                                {{ $t("MY_PROFILE.EMAIL") }}
                              </label>
                              <v-text-field
                                class="form-control"
                                v-model="ObserverDialog.Email"
                                :placeholder="$t('MY_PROFILE.EMAIL')"
                                :rules="[rules.required, rules.email]"
                                required
                              >
                                <template v-slot:prepend-inner>
                                  <inline-svg
                                    src="/media/icons/contact-gray.svg"
                                  />
                                </template>
                              </v-text-field>
                            </div>

                            <div class="col-lg-6 d-flex align-items-center">
                              <inline-svg
                                src="/media/icons/help-alt-5-primary.svg"
                              />
                              <span class="pl-3">
                                {{ $t("USERS.NEW_EMAIL_INFO") }}
                              </span>
                            </div>
                          </div>

                          <div class="row button-row py-3">
                            <button
                              class="btn btn-pill btn-white button-form button-form-primary mr-9"
                              @click="ObserverDialog.Show = false"
                            >
                              {{ $t("GENERIC.CANCEL") }}
                            </button>
                            <button
                              class="btn btn-pill btn-tertiary button-form"
                              :disabled="!ObserverDialog.Valid"
                              @click="saveObserverDialog"
                            >
                              {{ $t("GENERIC.OK") }}
                            </button>
                          </div>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <!--end::Observer dialog-->
                </div>
              </div>
            </div>
            <!-- end::Observers -->

            <!-- begin::InvoiceCenter -->
            <div
              v-if="InvoiceCenterOptions.length > 0"
              class="col-lg-6 form-group"
            >
              <label>{{ $t("OFFERS.COMMON.INVOICE_CENTER") }}</label>
              <select
                class="form-control select-down"
                v-model="Fields.InvoiceCenterID"
              >
                <option
                  v-for="ic in InvoiceCenterOptions"
                  :key="ic.InvoiceCenterID"
                  :value="ic.InvoiceCenterID"
                  class="text-primary"
                >
                  {{ ic.Nick }}
                </option>
              </select>
            </div>
            <!-- end::InvoiceCenter -->
          </div>
        </div>
        <!-- end::Other data -->

        <!--begin::Route-->
        <div
          class="col-lg-12 px-0 my-3"
          v-for="whichLeg in InitialData.Back.Vehicles.length > 0
            ? ['There', 'Back']
            : ['There']"
          :key="whichLeg"
        >
          <div class="text-primary font-weight-bolder text-center">
            <h2 v-if="whichLeg === 'There'">
              {{
                InitialData.Back.Vehicles.length > 0
                  ? $t("OFFERS.COMMON.THERE").toUpperCase()
                  : ""
              }}
            </h2>
            <h2 v-else>
              {{ $t("OFFERS.COMMON.BACK").toUpperCase() }}
            </h2>
          </div>

          <!--begin::Leg Vehicles-->
          <div class="row">
            <div class="form-group col-lg-12">
              <h5 class="font-weight-bolder">
                <inline-svg src="/media/icons/car-primary.svg" class="mr-3" />
                {{
                  $t("OFFERS.COMMON.VEHICLES") +
                    " (" +
                    InitialData[whichLeg].Vehicles.length +
                    ")"
                }}
              </h5>
              <div
                v-for="(vehicle, v) in InitialData[whichLeg].Vehicles"
                :key="whichLeg + '_Vehicle_' + v"
                class="mb-3"
              >
                <strong>{{ vehicle.Plate }}</strong>
                -
                {{
                  vehicle.Brand +
                    ", " +
                    vehicle.Model +
                    ", " +
                    vehicle.Version +
                    ", " +
                    vehicle.Year +
                    ". " +
                    vehicle.VehicleTypeName +
                    ", " +
                    vehicle.VehicleStatusName
                }}.
                <div
                  v-for="whichSideServices in ['Origin', 'Destination']"
                  :key="
                    whichLeg +
                      '_Vehicle_' +
                      v +
                      '_Services_' +
                      whichSideServices
                  "
                >
                  <span class="pl-9">
                    {{
                      $t(
                        "PRICE_SEARCH.STEP3.EXTRA_DATA.SERVICES_" +
                          whichSideServices.toUpperCase()
                      )
                    }}:
                  </span>
                  <span
                    v-if="
                      !vehicle['Services' + whichSideServices] ||
                        vehicle['Services' + whichSideServices].length === 0
                    "
                  >
                    {{ $t("GENERIC.N_D") }}.
                  </span>
                  <span v-else>
                    {{ vehicle["Services" + whichSideServices].join(", ") }}.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--end::Leg Vehicles-->

          <!--begin::Leg Dates-->
          <div class="row">
            <!--begin::Leg origin date-->
            <div class="col-lg-6 form-group">
              <h5 class="font-weight-bolder">
                <inline-svg
                  src="/media/icons/calendar-alt-1-primary.svg"
                  class="mr-3"
                />
                {{ $t("OFFERS.COMMON.ORIGIN_DATE") }}
              </h5>
              <p>
                <strong>{{ $t("OFFERS.COMMON.AVAILABILITY") }}:</strong>
                {{ formatOriginDateDesired(whichLeg) }}
              </p>

              <p class="mt-10 mb-3">
                <strong>{{ $t("OFFERS.COMMON.PLANNED") }}</strong>
              </p>
              <v-text-field
                class="form-control"
                :class="canEditDates ? 'bg-white' : ''"
                :value="formatOriginDatePlanned(whichLeg)"
                readonly
                required
                :rules="[rules.required]"
                @click="openOriginDatePlannedDialog(whichLeg)"
              />

              <!--begin::OriginDatePlanned dialog-->
              <v-dialog
                v-model="OriginDatePlannedDialog.Show"
                :return-value="OriginDatePlannedDialog.Value"
                persistent
                width="320px"
              >
                <v-date-picker
                  id="OriginDatePlannedDialog"
                  v-model="OriginDatePlannedDialog.Value"
                  color="tertiary"
                  scrollable
                  range
                  :locale="DateLocale"
                  :first-day-of-week="DateFirstDayOfWeek"
                  :min="Today"
                  :max="OneYear"
                >
                  <div class="row button-row">
                    <button
                      class="btn btn-pill btn-white button-form button-form-primary mr-9"
                      style="min-width: 125px"
                      @click="cancelOriginDatePlannedDialog"
                    >
                      {{ $t("GENERIC.CANCEL") }}
                    </button>
                    <button
                      class="btn btn-pill btn-tertiary button-form"
                      style="min-width: 125px"
                      @click="saveOriginDatePlannedDialog"
                    >
                      {{ $t("GENERIC.OK") }}
                    </button>
                  </div>
                </v-date-picker>
              </v-dialog>
              <!--end::OriginDatePlanned dialog-->
            </div>
            <!--end::Leg origin date-->

            <!--begin::Leg destination date-->
            <div class="col-lg-6 form-group">
              <h5 class="font-weight-bolder">
                <inline-svg
                  src="/media/icons/calendar-alt-1-primary.svg"
                  class="mr-3"
                />
                {{ $t("OFFERS.COMMON.DESTINATION_DATE") }}
              </h5>
              <p>
                <strong>
                  {{ $t("OFFERS.COMMON.DESTINATION_DATE_RANGE") }}:
                </strong>
                {{ formatDestinationDates(whichLeg, "Desired") }}
                <br />
                <strong>
                  {{ $t("OFFERS.COMMON.DESTINATION_DATE_TIME") }}:
                </strong>
                {{ formatDestinationTimes(whichLeg) }}
              </p>

              <p class="mb-3">
                <strong>{{ $t("OFFERS.COMMON.PLANNED") }}</strong>
              </p>
              <v-text-field
                class="form-control"
                :class="canEditDates ? 'bg-white' : ''"
                :value="formatDestinationDates(whichLeg, 'Planned')"
                readonly
                required
                :rules="[rules.required]"
                @click="openDestinationDatePlannedDialog(whichLeg)"
              />

              <!--begin::DestinationDatePlanned dialog-->
              <v-dialog
                v-model="DestinationDatePlannedDialog.Show"
                :return-value="DestinationDatePlannedDialog.Value"
                persistent
                width="320px"
              >
                <v-date-picker
                  id="DestinationDatePlannedDialog"
                  v-model="DestinationDatePlannedDialog.Value"
                  color="tertiary"
                  scrollable
                  range
                  :locale="DateLocale"
                  :first-day-of-week="DateFirstDayOfWeek"
                  :min="Today"
                  :max="OneYear"
                >
                  <div class="row button-row">
                    <button
                      class="btn btn-pill btn-white button-form button-form-primary mr-9"
                      style="min-width: 125px"
                      @click="cancelDestinationDatePlannedDialog"
                    >
                      {{ $t("GENERIC.CANCEL") }}
                    </button>
                    <button
                      class="btn btn-pill btn-tertiary button-form"
                      style="min-width: 125px"
                      @click="saveDestinationDatePlannedDialog"
                    >
                      {{ $t("GENERIC.OK") }}
                    </button>
                  </div>
                </v-date-picker>
              </v-dialog>
              <!--end::DestinationDatePlanned dialog-->
            </div>
            <!--end::Leg destination date-->
          </div>
          <!--end::Leg Dates-->

          <!--begin::Leg Notes-->
          <div class="row">
            <div class="col-lg-12 form-group">
              <label>
                {{ $t("OFFERS.COMMON.SENDER_NOTES") }}
                <span class="font-weight-normal">
                  ({{ $t("GENERIC.OPTIONAL") }})
                </span>
              </label>
              <p v-if="isTransporter">
                {{
                  InitialData[whichLeg].SenderNotes.length > 0
                    ? InitialData[whichLeg].SenderNotes
                    : $t("GENERIC.N_D")
                }}
              </p>
              <textarea
                v-else
                rows="3"
                class="form-control"
                v-model="Fields[whichLeg].SenderNotes"
              ></textarea>
            </div>

            <div v-if="isTransporter" class="col-lg-12 form-group">
              <label>
                {{ $t("OFFERS.COMMON.TRANSPORTER_NOTES") }}
                <span class="font-weight-normal">
                  ({{ $t("GENERIC.OPTIONAL") }})
                </span>
              </label>
              <textarea
                rows="3"
                class="form-control"
                v-model="Fields[whichLeg].TransporterNotes"
              ></textarea>
            </div>
          </div>
          <!--end::Leg Notes-->
        </div>
        <!--end::Route-->
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push(BackRoute)"
        >
          <inline-svg
            src="/media/icons/arrow-return-primary.svg"
            class="mr-3"
          />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          v-if="InitialData.CanCancel"
          class="btn btn-pill btn-danger button-form mr-9"
          :disabled="IsCancelling || IsFinishing"
          @click="onCancelOffer"
        >
          {{ $t("OFFERS.COMMON.CANCEL_OFFER") }}
        </button>

        <button
          v-if="InitialData.CanFinish"
          class="btn btn-pill btn-danger button-form mr-9"
          :disabled="IsCancelling || IsFinishing"
          @click="onRejectOffer"
        >
          {{ $t("OFFERS.COMMON.REJECT_OFFER") }}
        </button>

        <button
          v-if="InitialData.CanFinish && !anythingChanged"
          class="btn btn-pill btn-tertiary button-form"
          :disabled="IsCancelling || IsFinishing"
          @click="onAcceptOffer"
        >
          {{ $t("OFFERS.COMMON.ACCEPT_OFFER") }}
        </button>

        <button
          v-if="InitialData.CanEdit && somethingImportantChanged"
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("OFFERS.COMMON.SEND_COUNTEROFFER") }}
        </button>

        <button
          v-if="
            InitialData.CanEdit && !somethingImportantChanged && anythingChanged
          "
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::OfferStandard edit -->
</template>

<script>
import { mapGetters } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import moment from "moment";
import Swal from "sweetalert2";
import i18nService from "@/core/services/i18n.service.js";

import InvoiceCenterService from "@/core/services/api/v2/invoicecenter.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import ShipmentService from "@/core/services/api/v2/shipment.service";
import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "OffersStandardEdit",
  props: {
    ShipmentId: {
      type: Number,
      required: true,
      default: -1
    },
    BackRoute: {
      type: String,
      required: true,
      default: "home"
    }
  },

  data() {
    return {
      Valid: true,
      IsCancelling: false,
      IsFinishing: false,
      InitialData: null,
      Fields: {
        Price: 0,
        There: {
          OriginDatePlannedFrom: "",
          OriginDatePlannedTo: "",
          DestinationDatePlannedFrom: "",
          DestinationDatePlannedTo: "",
          SenderNotes: "",
          TransporterNotes: ""
        },
        Back: {
          OriginDatePlannedFrom: "",
          OriginDatePlannedTo: "",
          DestinationDatePlannedFrom: "",
          DestinationDatePlannedTo: "",
          SenderNotes: "",
          TransporterNotes: ""
        },
        InvoiceCenterID: 0,
        Observers: []
      },
      ProvinceNames: [],
      InvoiceCenterOptions: [],
      ObserverOptions: [],
      ObserverDialog: {
        Show: false,
        Valid: false,
        Nick: "",
        Name: "",
        Email: "",
        NickCompany: ""
      },
      OriginDatePlannedDialog: {
        Show: false,
        WhichLeg: "",
        Value: []
      },
      DestinationDatePlannedDialog: {
        Show: false,
        WhichLeg: "",
        Value: []
      },
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      DateFirstDayOfWeek: 1,
      DateLocale: "es-es",
      Today: "",
      OneYear: "",
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL"),
        requiredMin0: value => {
          let value2 = parseInt(value);
          if (isNaN(value2) || value2 < 0) {
            return this.$i18n.t("VALIDATION.MIN_VALUE", { min: 0 });
          } else {
            return true;
          }
        }
      }
    };
  },

  computed: {
    ...mapGetters([
      "currentUserRoleID",
      "rolesList",
      "currentUserCompanyIsPremium"
    ]),

    isTransporter() {
      return this.currentUserRoleID === this.rolesList.ID_TRANSPORTER_ADMIN;
    },

    canEditPrice() {
      let can = false;

      if (this.InitialData && this.InitialData.CanEdit) {
        if (this.isTransporter) {
          can =
            this.InitialData.ShipmentStatusID === 2 ||
            this.InitialData.ShipmentStatusID === 3 ||
            this.InitialData.ShipmentStatusID === 5;
        } else {
          can = this.InitialData.ShipmentStatusID === 4;
        }
      }

      return can;
    },
    canEditDates() {
      let can = false;

      if (this.InitialData && this.InitialData.CanEdit) {
        if (this.isTransporter) {
          can =
            this.InitialData.ShipmentStatusID === 2 ||
            this.InitialData.ShipmentStatusID === 3 ||
            this.InitialData.ShipmentStatusID === 5;
        }
      }

      return can;
    },

    somethingImportantChanged() {
      // Check if user changed some critical filed that implies a counter-offer
      // (as of 2021-09, just Price)
      let changed = false;

      if (this.canEditPrice) {
        changed =
          parseFloat(this.InitialData.Price) !== parseFloat(this.Fields.Price);
      }

      return changed;
    },

    anythingChanged() {
      // Check if user changed any field, either...
      let changed = false;

      if (this.somethingImportantChanged) {
        // ...that implies a counter-offer...
        changed = true;
      } else {
        // ...or just need to be saved
        let legList = ["There", "Back"];

        let notesList = ["SenderNotes", "TransporterNotes"];
        legList.forEach(whichLeg => {
          notesList.forEach(whichNote => {
            if (
              this.InitialData[whichLeg][whichNote] !==
              this.Fields[whichLeg][whichNote]
            ) {
              changed = true;
            }
          });
        });

        if (this.canEditDates) {
          let datesList = [
            "OriginDatePlannedFrom",
            "OriginDatePlannedTo",
            "DestinationDatePlannedFrom",
            "DestinationDatePlannedTo"
          ];

          legList.forEach(whichLeg => {
            datesList.forEach(whichDate => {
              if (
                this.InitialData[whichLeg][whichDate] !==
                this.Fields[whichLeg][whichDate]
              ) {
                changed = true;
              }
            });
          });
        }

        if (
          this.InitialData.Observers.length !== this.Fields.Observers.length
        ) {
          changed = true;
        }

        if (this.InitialData.InvoiceCenterID !== this.Fields.InvoiceCenterID) {
          changed = true;
        }
      }

      return changed;
    }
  },

  created() {
    this.Today = moment().format(this.DateFormatEN);
    this.OneYear = moment()
      .add(1, "years")
      .format(this.DateFormatEN);

    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFirstDayOfWeek = 0;
      this.DateLocale = "en-en";
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFirstDayOfWeek = 1;
      this.DateLocale = "es-es";
      this.DateFormatLang = this.DateFormatES;
    }
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadProvinceNames();
    if (!this.isTransporter) {
      this.loadObserverOptions();
      this.loadInvoiceCenterOptions();
    }

    this.loadOfferData();
  },

  methods: {
    loadProvinceNames() {
      this.ProvinceNames = [];

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceNames[p.ProvinceID] = p.Name;
        });
      });
    },

    loadObserverOptions() {
      this.ObserverOptions = [];

      UserService.listAllObservers(false).then(response => {
        // Parse them into v-select structure
        let list = [];
        response.forEach(o => {
          list.push({
            value: o.UserID,
            text: o.Nick + " (" + o.NickCompany + ")"
          });
        });

        // Sort by text
        list.sort((a, b) => {
          return a.text < b.text;
        });

        this.ObserverOptions = list;
      });
    },

    loadInvoiceCenterOptions() {
      this.InvoiceCenterOptions = [];

      if (this.currentUserCompanyIsPremium) {
        InvoiceCenterService.listAll().then(response => {
          let list = [];
          list.push({
            InvoiceCenterID: "",
            Nick: ""
          });

          response.forEach(ic => {
            list.push({
              InvoiceCenterID: ic.InvoiceCenterID,
              Nick: ic.Nick
            });
          });

          this.InvoiceCenterOptions = list;
        });
      }
    },

    loadOfferData() {
      ShipmentService.getAtOffer(this.ShipmentId).then(response => {
        if (response === 404) {
          this.$router.push("/manager/offers?ActiveTab=standard");
        } else {
          this.InitialData = response;

          this.Fields.Price = response.Price;
          this.Fields.Observers = response.Observers;
          this.Fields.InvoiceCenterID = response.InvoiceCenterID;

          ["There", "Back"].forEach(whichLeg => {
            let odpFrom = response[whichLeg].OriginDatePlannedFrom;
            if (odpFrom.length === 0) {
              odpFrom = response[whichLeg].OriginDateDesired;
            }
            this.Fields[whichLeg].OriginDatePlannedFrom = odpFrom;

            let odpTo = response[whichLeg].OriginDatePlannedTo;
            if (odpTo.length === 0) {
              odpTo = response[whichLeg].OriginDateDesired;
            }
            this.Fields[whichLeg].OriginDatePlannedTo = odpTo;

            let ddpFrom = response[whichLeg].DestinationDatePlannedFrom;
            if (ddpFrom.length === 0) {
              ddpFrom = response[whichLeg].DestinationDateDesiredFrom;
            }
            this.Fields[whichLeg].DestinationDatePlannedFrom = ddpFrom;

            let ddpTo = response[whichLeg].DestinationDatePlannedTo;
            if (ddpTo.length === 0) {
              ddpTo = response[whichLeg].DestinationDateDesiredTo;
            }
            this.Fields[whichLeg].DestinationDatePlannedTo = ddpTo;

            this.Fields[whichLeg].SenderNotes = response[whichLeg].SenderNotes;
            this.Fields[whichLeg].TransporterNotes =
              response[whichLeg].TransporterNotes;
          });

          // Remove page loader
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      });
    },

    reloadOfferData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.loadOfferData();
    },

    formatStatusName(statusID) {
      if (statusID <= 9 || statusID == 14) {
        // It's really at offer phase
        return this.$t("SHIPMENT_STATUS_NAMES_BY_ID." + statusID);
      } else {
        // It's after offer phase
        return this.$t("OFFERS.COMMON.STATUS_AFTER_OFFER");
      }
    },

    formatStatusClass(statusID) {
      if (statusID <= 5) {
        // It's negotiating
        return "";
      } else if (statusID == 6 || statusID == 7 || statusID == 14) {
        // It's rejected or cancelled
        return "text-danger border-danger";
      } else {
        // It's accepted, after offer phase
        return "text-success border-success";
      }
    },

    formatCreatedAt() {
      return moment(this.InitialData.CreatedAt).format(this.DateFormatLang);
    },

    formatOriginDateDesired(whichLeg) {
      let value = "";

      if (this.InitialData[whichLeg]) {
        let whichDate = this.InitialData[whichLeg].OriginDateDesired;

        if (whichLeg === "Back" && parseInt(whichDate.substring(0, 4)) < 2000) {
          // Special value for "when There arrives"
          value = this.$t("OFFERS.COMMON.ORIGIN_DATE_DESIRED_BACK_THERE");
        } else {
          value = moment(whichDate).format(this.DateFormatLang);
        }
      }

      return value;
    },

    formatOriginDatePlanned(whichLeg) {
      let value = "";

      if (this.InitialData[whichLeg]) {
        let from = this.Fields[whichLeg].OriginDatePlannedFrom;
        let to = this.Fields[whichLeg].OriginDatePlannedTo;

        if (from === "" && to === "") {
          value = "";
        } else if (from === to) {
          value = moment(from).format(this.DateFormatLang);
        } else {
          value =
            moment(from).format(this.DateFormatLang) +
            " - " +
            moment(to).format(this.DateFormatLang);
        }
      }

      return value;
    },

    formatDestinationDates(whichLeg, whichBlock) {
      let value = "";

      if (this.InitialData[whichLeg]) {
        let from = "";
        let to = "";

        if (whichBlock === "Desired") {
          from = this.InitialData[whichLeg].DestinationDateDesiredFrom;
          to = this.InitialData[whichLeg].DestinationDateDesiredTo;
        } else if (whichBlock === "Planned") {
          from = this.Fields[whichLeg].DestinationDatePlannedFrom;
          to = this.Fields[whichLeg].DestinationDatePlannedTo;
        }

        if (from === "" && to === "") {
          value =
            whichBlock === "Desired" ? this.$t("OFFERS.COMMON.FLEXIBLE") : "";
        } else if (from === to) {
          value = moment(from).format(this.DateFormatLang);
        } else {
          value =
            moment(from).format(this.DateFormatLang) +
            " - " +
            moment(to).format(this.DateFormatLang);
        }
      }

      return value;
    },

    formatDestinationTimes(whichLeg) {
      let value = "";

      if (this.InitialData[whichLeg]) {
        let from = this.InitialData[whichLeg].DestinationDateDesiredTimeFrom;
        let to = this.InitialData[whichLeg].DestinationDateDesiredTimeTo;
        value = from + " - " + to;
      }

      return value;
    },

    openObserverDialog() {
      this.ObserverDialog.Nick = "";
      this.ObserverDialog.NickCompany = "";
      this.ObserverDialog.Name = "";
      this.ObserverDialog.Email = "";
      this.ObserverDialog.Show = true;
    },

    saveObserverDialog(e) {
      e.preventDefault();

      if (this.$refs.formObserverDialog.validate()) {
        // Disable submit button
        this.ObserverDialog.Valid = false;

        // Send request to create new Observer: we get back his ID
        UserService.createObserverProfile(this.ObserverDialog).then(
          response => {
            if (response.UserID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.loadObserverOptions();
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("OBSERVERS.ERROR_409");
              } else if (response === 418) {
                whyError = this.$i18n.t("OBSERVERS.ERROR_418");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button and hide dialog
            this.ObserverDialog.Valid = true;
            this.ObserverDialog.Show = false;
          }
        );
      }
    },

    openOriginDatePlannedDialog(whichLeg) {
      if (this.canEditDates) {
        this.OriginDatePlannedDialog.WhichLeg = whichLeg;

        let oldValueFrom = this.Fields[whichLeg].OriginDatePlannedFrom;
        if (
          whichLeg === "Back" &&
          parseInt(oldValueFrom.substring(0, 4)) < 2000
        ) {
          // Special value for "when There arrives" - but now we need a specific date
          oldValueFrom = moment().format(this.DateFormatEN);
          this.OriginDatePlannedDialog.Value = [oldValueFrom, oldValueFrom];
        }

        this.OriginDatePlannedDialog.Show = true;
      }
    },

    saveOriginDatePlannedDialog() {
      if (this.canEditDates) {
        if (this.OriginDatePlannedDialog.Value.length === 2) {
          let whichLeg = this.OriginDatePlannedDialog.WhichLeg;

          let newValues = this.OriginDatePlannedDialog.Value.sort();
          this.Fields[whichLeg].OriginDatePlannedFrom = newValues[0];
          this.Fields[whichLeg].OriginDatePlannedTo = newValues[1];
        }

        this.OriginDatePlannedDialog.Show = false;
      }
    },

    cancelOriginDatePlannedDialog() {
      if (this.canEditDates) {
        // Reset picker to old date
        let whichLeg = this.OriginDatePlannedDialog.WhichLeg;

        let oldDates = [
          this.Fields[whichLeg].OriginDateDesiredFrom,
          this.Fields[whichLeg].OriginDateDesiredTo
        ];
        this.OriginDatePlannedDialog.Value = oldDates;

        this.OriginDatePlannedDialog.Show = false;
      }
    },

    openDestinationDatePlannedDialog(whichLeg) {
      if (this.canEditDates) {
        this.DestinationDatePlannedDialog.WhichLeg = whichLeg;
        this.DestinationDatePlannedDialog.Show = true;
      }
    },

    saveDestinationDatePlannedDialog() {
      if (this.canEditDates) {
        if (this.DestinationDatePlannedDialog.Value.length === 2) {
          let whichLeg = this.DestinationDatePlannedDialog.WhichLeg;

          let newValues = this.DestinationDatePlannedDialog.Value.sort();
          this.Fields[whichLeg].DestinationDatePlannedFrom = newValues[0];
          this.Fields[whichLeg].DestinationDatePlannedTo = newValues[1];
        }

        this.DestinationDatePlannedDialog.Show = false;
      }
    },

    cancelDestinationDatePlannedDialog() {
      if (this.canEditDates) {
        // Reset picker to old date
        let whichLeg = this.DestinationDatePlannedDialog.WhichLeg;

        let oldDates = [
          this.Fields[whichLeg].DestinationDateDesiredFrom,
          this.Fields[whichLeg].DestinationDateDesiredTo
        ];
        this.DestinationDatePlannedDialog.Value = oldDates;

        this.DestinationDatePlannedDialog.Show = false;
      }
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // Send request:
        ShipmentService.updateAtOffer(this.ShipmentId, this.Fields).then(
          statusCode => {
            if (statusCode >= 400) {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (statusCode === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              } else if (statusCode === 409) {
                whyError = this.$i18n.t("VALIDATION.INVALID_DATES");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              // Reload data, because maybe ShipmentStatus changed
              this.loadOfferData();
            }

            // Reset submit button
            this.Valid = true;
          }
        );
      }
    },

    onCancelOffer() {
      Swal.fire({
        title: this.$i18n.t("OFFERS.COMMON.CANCEL_ALERT"),
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.YES"),
        cancelButtonText: this.$i18n.t("GENERIC.NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          // Disable cancel button
          this.IsCancelling = true;

          ShipmentService.cancelAtOffer(this.ShipmentId).then(statusCode => {
            if (statusCode >= 400) {
              this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              Swal.fire({
                text: this.$i18n.t("OFFERS.COMMON.CANCEL_OK"),
                icon: "success",
                confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                customClass: {
                  confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                },
                buttonsStyling: false,
                focusConfirm: false
              });

              // Reload data, to redraw with new ShipmentStatus
              this.loadOfferData();

              // Re-enable cancel button
              this.IsCancelling = false;
            }
          });
        }
      });
    },

    onAcceptOffer() {
      Swal.fire({
        title: this.$i18n.t("OFFERS.COMMON.ACCEPT_ALERT"),
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.YES"),
        cancelButtonText: this.$i18n.t("GENERIC.NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          // Disable both finish buttons
          this.IsFinishing = true;

          ShipmentService.acceptAtOffer(this.ShipmentId).then(statusCode => {
            if (statusCode >= 400) {
              this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              Swal.fire({
                text: this.$i18n.t("OFFERS.COMMON.ACCEPT_OK"),
                icon: "success",
                confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                customClass: {
                  confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                },
                buttonsStyling: false,
                focusConfirm: false
              });

              // Negotiation phase ended, so back to offer list
              // TO-DO TBD of to a single-Shipment profile at tracking phase ???
              setTimeout(
                () => this.$router.push("/manager/offers?ActiveTab=standard"),
                2000
              );
            }

            // Re-enable both finish buttons
            this.IsFinishing = false;
          });
        }
      });
    },

    onRejectOffer() {
      Swal.fire({
        title: this.$i18n.t("OFFERS.COMMON.REJECT_ALERT"),
        text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t("GENERIC.YES"),
        cancelButtonText: this.$i18n.t("GENERIC.NO"),
        customClass: {
          confirmButton: "btn btn-pill btn-danger button-form",
          cancelButton: "btn btn-pill btn-white button-form button-form-primary"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.isConfirmed) {
          // Disable both finish buttons
          this.IsFinishing = true;

          ShipmentService.rejectAtOffer(this.ShipmentId).then(statusCode => {
            if (statusCode >= 400) {
              this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                variant: "danger",
                solid: true
              });
            } else {
              Swal.fire({
                text: this.$i18n.t("OFFERS.COMMON.REJECT_OK"),
                icon: "success",
                confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                customClass: {
                  confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                },
                buttonsStyling: false,
                focusConfirm: false
              });

              // Reload data, to redraw with new ShipmentStatus
              this.loadOfferData();

              // Re-enable both finish buttons
              this.IsFinishing = false;
            }
          });
        }
      });
    }
  }
};
</script>
